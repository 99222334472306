import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Header } from './components/Header';
import { Home } from './components/Home';
import { Placas } from './components/Placas';
import { Reportes } from './components/Reportes';
import { Salida } from './components/Salida';
import { Setup } from './components/Setup';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Header/>
      <Router>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/Reportes" element={<Reportes/>} />
          <Route path="/Setup" element={<Setup/>} />
          <Route path="/Placas/:nue" element={<Placas/>} />
          <Route path="/Salida/:nue" element={<Salida/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
