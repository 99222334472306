import React, { useEffect, useState } from 'react'
import { Cajon } from './Cajon';

export const Home = () => {

    const [data, setData] = useState([]);

    const fetchData = async () => {
        const response = await fetch('https://app.teziutlan.com/bdd/cajones.php');
        const data = await response.json();
        setData(data);
    };

    useEffect(() => {
        // Fetch data initially
        fetchData();

        // Function to call fetchData on popstate event
        const handlePopState = () => {
            fetchData();
        };

        // Add event listener for popstate
        window.addEventListener('popstate', handlePopState);

        // Set up interval to fetch data periodically
        const intervalId = setInterval(fetchData, 10000); // Update every 10 seconds

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('popstate', handlePopState);
            clearInterval(intervalId);
        };
        
    }, []);

  return (
    <div>
        <div className="container mt-3">
            
            {data.map((row, index) => (
                <div className="col-4 col-md-4 col-lg-2 mb-2" key={index} style={{float : 'left', paddingRight : '15px'}}>
                    <Cajon nue={row.numero_e} color={row.ai} ></Cajon>
                </div>
            ))}
        </div>
    </div>
  )
}