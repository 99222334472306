import React, { useEffect, useState } from 'react'

export const Reportes = () => {

  const [data, setData] = useState([]);

  const fetchData = async () => {
    const response = await fetch('https://app.teziutlan.com/bdd/report.php');
    const data = await response.json();
    setData(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div>

      <p>Este reporte solo muestra los registros cerrados de hoy.</p>

      <table class="table">
        <thead>
          <tr>
            <th scope="col"># E</th>
            <th scope="col">Fecha de cobro</th>
            <th scope="col">Horas</th>
            <th scope="col">Pago</th>
          </tr>
        </thead>
        <tbody>
        {data.map((row, index) => (
          <tr>
            <th scope="row">{row.numero_e}</th>
            <td>{row.fecha_salida}</td>
            <td>{row.horas_pagar}</td>
            <td>{row.total_pagar}</td>
          </tr>
        ))}
        </tbody>
      </table>

      <hr/>
      <button className="form-control" onClick={handlePrint}>Imprimir</button>

    </div>
  )
}