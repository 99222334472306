import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import moment from 'moment';


const calculate = (startDate, endDate) => {

    // Función para reformatear la fecha al estándar ISO 8601
    const reformatDate = (dateStr) => {
        const [date, time] = dateStr.split(' ');
        const [day, month, year] = date.split('/');
        return `${year}-${month}-${day}T${time}`;
    };

    const start = moment(reformatDate(startDate));
    const end = moment(reformatDate(endDate));
    const duration = moment.duration(end.diff(start));

    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.minutes());

    return `${hours}.${minutes}`;
};

// Función para calcular la diferencia en horas entre dos fechas
const calculateHours = (startDate, endDate) => {
    // Función para reformatear la fecha al estándar ISO 8601
    const reformatDate = (dateStr) => {
        const [date, time] = dateStr.split(' ');
        const [day, month, year] = date.split('/');
        return `${year}-${month}-${day}T${time}`;
    };
    // Reformatear las fechas
    const start = new Date(reformatDate(startDate));
    const end = new Date(reformatDate(endDate));
    // Verificar si las fechas son válidas
    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        return 'Fechas inválidas';
    }
    // Calcular la diferencia en milisegundos
    const diffMs = end - start;
    // Convertir la diferencia a horas y redondear a dos decimales
    const diffHrs = (diffMs / (1000 * 60 * 60)).toFixed(2);
    return diffHrs;
};

// Función para aplicar el redondeo hacia arriba
const roundUpHours = (hours) => {
    return Math.ceil(hours);
};

const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    // Formatear la fecha y la hora
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
};

export const Salida = () => {

    const { nue } = useParams();
    const costPerHour = 15; // Costo por hora

    // Fecha y hora actual
    const [currentDate, setCurrentDate] = useState(new Date());
    useEffect(() => {
        setCurrentDate(new Date());
        return
    }, []);

    const [data, setData] = useState([]);
    useEffect(() => {
        
        const noe = {nue};
        const data = {
            noe
        };

        fetch('https://app.teziutlan.com/bdd/venta.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            setData(data); // Actualizar el estado con los datos recibidos
            console.log('Success:', data);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }, []);

    // imprimir
    const navigate = useNavigate();
    const handlePrint = () => {

        // Extraer los valores de los elementos span
        const noest = document.getElementById('noe').innerText;
        const iduboleto = document.getElementById('idboleto').innerText;
        const salida = document.getElementById('salida').innerText;
        const horas = document.getElementById('horas').innerText;
        const pagar = document.getElementById('pagar').innerText;
        
        // Crear un objeto con los datos a enviar
        const data = {
            noest,
            iduboleto,
            salida,
            horas,
            pagar
        };

        window.print();

        // Enviar los datos a la URL usando fetch
        fetch('https://app.teziutlan.com/bdd/cerrar.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
        //fetchData();

        setTimeout(() => {
            navigate('/');
        }, 3000);

    };

  return (
    <div>
        <h1 className="text-center">Mario Vinos</h1>
        {data.map((row, index) => {
            const fechaEntrada = row.fecha_entrada;
            const fechaSalida = formatDate(currentDate);

            const hoursStayed = calculateHours(fechaEntrada, fechaSalida);
            
            const hoursStayed2 = calculate(fechaEntrada, fechaSalida);
            const [hours, minutes] = hoursStayed2.split('.').map(Number);
            const roundedHours = roundUpHours(hoursStayed);
            let totalToPay = hoursStayed2 * costPerHour;

            return (
                <div key={index}>
                    <h3 className="mt-5" style={{fontSize : '40px'}}><strong>No. Est.:</strong> <span id="noe">{row.numero_e}</span></h3>
                    <h3 style={{fontSize : '40px'}}><strong>Boleto:</strong><br/> <span id="idboleto">{row.idu_boleto}</span></h3>
                    <h3 style={{fontSize : '40px'}}><strong>Placas:</strong> <span id="placa">{row.placas}</span></h3>
                    <h3 style={{fontSize : '40px'}}><strong>Entrada:</strong><br/> <span id="entrada">{fechaEntrada}</span></h3>
                    <h3 style={{fontSize : '40px'}}><strong>Salida:</strong><br/> <span id="salida"> {fechaSalida} </span></h3>
                    <hr/>
                    <h3 style={{fontSize : '40px'}}><strong>Tiempo de estancia:</strong> <span id="horas"> {hours}:{minutes} </span></h3>
                    <h3 style={{fontSize : '40px'}}><strong>Total a pagar:</strong> <span id="pagar"> {totalToPay.toFixed(2)} MXN</span></h3>
                </div>
            );
        })}
        <hr/>
        <p>NO NOS HACEMOS RESPONSABLES DE ROBO, DAÑOS, PERDIDA PARCIAL O TOTAL, U OBJETOS DEJADOS EN SU VEHICULO.</p>
        <hr/>
        <button className="form-control" onClick={handlePrint}>Imprimir</button>
    </div>
  )
}