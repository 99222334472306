import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    // Formatear la fecha y la hora
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
};

export const MostrarDatos = ({ placa, noe }) => {

    // ID Único de Boleto
    const uniqueId = uuidv4().slice(0, 13);

    // Fecha y hora actual
    const [currentDate, setCurrentDate] = useState(new Date());
    useEffect(() => {
        setCurrentDate(new Date());
        return
    }, []);

    // imprimir
    const navigate = useNavigate();
    const handlePrint = () => {

        // Extraer los valores de los elementos span
        const idBoleto = document.getElementById('idboleto').innerText;
        const fechaYHora = document.getElementById('fechayhora').innerText;
        const noe = document.getElementById('noe').innerText;
        const placa = document.getElementById('placa').innerText;

        // Crear un objeto con los datos a enviar
        const data = {
            idBoleto,
            fechaYHora,
            noe,
            placa
        };

        window.print();

        // Enviar los datos a la URL usando fetch
        fetch('https://app.teziutlan.com/bdd/addboleto.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
        //fetchData();

        setTimeout(() => {
            navigate('/');
        }, 3000);

    };

  return (
    <div>
        <h1 className="text-center" style={{fontSize : '55px'}}>Mario Vinos</h1>
        <h3 className="mt-5" style={{fontSize : '55px'}}><strong>Boleto:</strong><br></br> <span id="idboleto">{uniqueId}</span></h3>
        <h3 style={{fontSize : '55px'}}><strong>Fecha y Hora:</strong><br></br> <span id="fechayhora"> {formatDate(currentDate)} </span></h3>
        <h3 style={{fontSize : '55px'}}><strong>No. Est.:</strong> <span id="noe"> {noe} </span></h3>
        <h3 style={{fontSize : '55px'}}><strong>Placas:</strong> <span id="placa"> {placa} </span></h3>
        <hr/>
        <p>NO NOS HACEMOS RESPONSABLES DE ROBO, DAÑOS, PERDIDA PARCIAL O TOTAL, U OBJETOS DEJADOS EN SU VEHICULO.</p>
        <hr/>
        <button className="form-control" onClick={handlePrint}>Imprimir</button>
    </div>
  )
}
