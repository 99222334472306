import React from 'react'
import { Navbar, Nav } from 'react-bootstrap';

export const Header = () => {
  return (
    <div>
        <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/">Estacionamiento</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
            <Nav.Link href="/">Inicio</Nav.Link>
            <Nav.Link href="/reportes">Reportes</Nav.Link>
            <Nav.Link href="/setup">Configuración</Nav.Link>
            </Nav>
        </Navbar.Collapse>
        </Navbar>
    </div>
  )
}