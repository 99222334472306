import { useParams } from 'react-router-dom';
import React, { useState } from 'react'
import { Placasform } from './Placasform';
import { MostrarDatos } from './MostrarDatos';

export const Placas = () => {

    const { nue } = useParams();

    const [placa, setPlaca] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handlePlacaSubmit = (placa) => {
        setPlaca(placa);
        setIsSubmitted(true);
    };

  return (
    <div>
        <div className="container mt-3">
            <div className="row">
                {!isSubmitted ? (
                    <Placasform noe={nue} onSubmit={handlePlacaSubmit} ></Placasform>
                ) : (
                    <MostrarDatos noe={nue} placa={placa}></MostrarDatos>
                )}
            </div>
        </div>
    </div>
  )
}