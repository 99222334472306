import React, { useState } from 'react'

export const Placasform = ({noe, onSubmit}) => {

    const [placa, setPlaca] = useState('');
    const [isValid, setIsValid] = useState(false);

    const handleChange = (e) => {
        const value = e.target.value.toUpperCase();
        setPlaca(value);
        setIsValid(value.length >= 3);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isValid) {
          onSubmit(placa);
        }
    };

  return (
    <div>
        <form onSubmit={handleSubmit}>
            <h3><strong>Estacionamiento:</strong> <span id="estnum"> {noe} </span></h3>
            <div>
                <label htmlFor="placa">Placa: </label>
                <input
                type="text"
                id="placa"
                name="placa"
                value={placa}
                onChange={handleChange}
                className="form-control text-uppercase"
                />
            </div>
            <button className="form-control" type="submit" disabled={!isValid}>Enviar</button>
        </form>
    </div>
  )
}
