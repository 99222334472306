import React from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

export const Setup = () => {

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await axios.get('https://app.teziutlan.com/bdd/clear.php');
      console.log('Database cleared:', response.data);

      setTimeout(() => {
        navigate('/');
      }, 2000);

    } catch (error) {
      console.error('Error clearing database:', error);
    }

  };

  const LimpiarBDD = () => {
    fetchData();
  };

  return (
    <div>
      <div className="container mt-3">
        <div className="row">
          <p>Al presionar este botón la Base de Datos quedara completamente limpia.</p>
          <button className="form-control btn btn-danger" onClick={LimpiarBDD}>Limpiar</button>
        </div>
      </div>
    </div>
  )
}