import React from 'react'

export const Cajon = ({nue, color}) => {

    let tipo;
    let link;

    if (color == 'inactivo') {
        tipo = 'card text-white bg-success';
        link =  `placas/${nue}`;
    } else {
        tipo = 'card text-white bg-danger';
        link =  `salida/${nue}`;
    }

  return (
    <div>
        <a href={link} className="text-decoration-none">
            <div className={tipo}>
                <div className="card-body text-center display-6">{nue}</div>
            </div>
        </a>
    </div>
  )
}
